import Link from "next/link";
import Logo from "./Logo";
import { useRouter } from "next/router";
import { Box, Button, Text, Header, Group, Anchor, Navbar, createStyles, Burger, Transition, Paper, Container } from "@mantine/core";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  logo: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({ variant: "light", color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor }).color,
    },
  },
}));

const links = [
  { label: "Blog", link: "/blog" },
  { label: "Post a Job", link: "/submit" },
  { label: "Built With React Native", link: "/built-with-react-native" },
];
const NavigationBar = ({ hideNavBar }) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const router = useRouter();

  const items = links.map((link) => (
    <Anchor
      key={link.label}
      href={link.link}
      className={cx(classes.link)}
      onClick={(event) => {
        event.preventDefault();
        setActive(link.link);
        router.push(link.link);
        close();
      }}
    >
      {link.label}
    </Anchor>
  ));

  return (
    <Header height={HEADER_HEIGHT} mb="lg" className={classes.root}>
      <Container className={classes.header}>
        <Link href="/" passHref>
          <Group component="a" aria-label="Logo">
            <Logo />
            <Text size="xl" weight={"bold"}>
              ReactNativeJobs.com
            </Text>
          </Group>
        </Link>
        {!hideNavBar && (
          <>
            <Group spacing={5} className={classes.links}>
              <Link href="/built-with-react-native" passHref>
                <Text component="a" size={"lg"} px="md" weight="500">
                  Built With React Native
                </Text>
              </Link>
              <Link href="/blog" passHref>
                <Text component="a" size={"lg"} px="md" weight="500">
                  Blog
                </Text>
              </Link>

              <Link href="/submit" passHref>
                <Button component="a">Post a Job</Button>
              </Link>
            </Group>
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
            <Transition transition="pop-top-right" duration={200} mounted={opened}>
              {(styles) => (
                <Paper className={classes.dropdown} withBorder style={styles}>
                  {items}
                </Paper>
              )}
            </Transition>
          </>
        )}
      </Container>
    </Header>
  );
};

export default NavigationBar;
