import Layout from "../components/Layout";
import { MantineProvider, Global } from "@mantine/core";
import Router from "next/router";
import { useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";

function MyApp({ Component, pageProps }) {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        /** Put your mantine theme override here */
        colorScheme: "light",
        colors: {
          green: [
            "#82A289",
            "#6F9B78",
            "#5E9469",
            "#4F8D5C",
            "#418750",
            "#338344",
            "#258039",
            "#296B38",
            "#2B5A36",
            "#2B4D33",
            "#2A422F",
            "#283A2C",
            "#263228",
          ],
          yellow: [
            "#FBFAF8",
            "#EDE7D9",
            "#E5D7B8",
            "#E2CB95",
            "#E7C26E",
            "#F5BE41",
            "#DFAC38",
            "#C69A35",
            "#A5853D",
            "#8B7440",
            "#776641",
            "#665A3F",
          ],
          blue: [
            "#C7D4D6",
            "#AFC6C8",
            "#97BABF",
            "#7DB2B9",
            "#63AEB7",
            "#45AEBB",
            "#31A9B8",
            "#388F99",
            "#3B7A81",
            "#3C696E",
            "#3A5B5F",
            "#384F52",
            "#354547",
          ],
          red: [
            "#DDCFCD",
            "#D0B6B3",
            "#C79E98",
            "#C3857C",
            "#C46B5E",
            "#CB4F3D",
            "#CF3721",
            "#AB3D2D",
            "#8E4034",
            "#783F37",
            "#663D37",
            "#583A36",
            "#4C3734",
          ],
          offWhite: ["#FEF3E2", "#F5DEBB", "#E7CA9E", "#D5B789", "#C3A578", "#B0956B", "#9F8660"],
          blackish: [
            "#3D3C3C",
            "#373637",
            "#323132",
            "#2E2D2D",
            "#2A2829",
            "#262525",
            "#232122",
            "#1F1E1F",
            "#1C1B1C",
            "#191819",
            "#171616",
            "#141414",
            "#121212",
          ],
        },
      }}
    >
      <Global
        styles={(theme) => ({
          "*, *::before, *::after": {
            boxSizing: "border-box",
          },

          body: {
            ...theme.fn.fontStyles(),
            backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
            color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
            lineHeight: theme.lineHeight,
          },
          a: {
            color: "inherit",
            "text-decoration": "none",
          },
        })}
      />
      <Layout>
        <Component {...pageProps} />
        <Analytics />
      </Layout>
    </MantineProvider>
  );
}

export default MyApp;
