import { Box, Button, Grid, Group, SimpleGrid, Text, TextField, TextInput } from "@mantine/core";
import React, { useRef, useState } from "react";

const Newsletter = ({ inline }) => {
  const [subscribeSuccessful, setSubscribeSuccessful] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const ref = useRef(null);

  const handleSubmit = async (e) => {
    console.log("submit", ref.current.value);
    e.preventDefault();
    setSubscribeSuccessful(null);
    setLoading(true);
    setError(null);

    fetch("/api/newsletter", {
      method: "POST",
      body: JSON.stringify({ email: ref.current.value }),
    })
      .then((result) => {
        setLoading(false);
        console.log(result);
        if (result.status === 200) {
          setSubscribeSuccessful(true);
          console.log("Form successfully submitted");
        } else {
          console.warn(result);
          setError(true);
        }
      })
      .catch((error) => alert(error))
      .finally(() => setLoading(false));
  };
  return (
    <SimpleGrid
      cols={2}
      color="offWhite"
      sx={(theme) => ({
        padding: 24,
        paddingRight: 0,
        alignItems: "center",
        justifyItems: "center",
        backgroundColor: inline ? theme.colors.offWhite[0] : theme.colors.white,
        marginBottom: 8,
        borderRadius: 12,
      })}
      breakpoints={[{ maxWidth: 755, cols: 1, spacing: "sm" }]}
    >
      <Text py="md">Get a weekly email with the best React Native jobs.</Text>
      <form id="newsletter" name="newsletter" method="post" onSubmit={handleSubmit}>
        <Box sx={(theme) => ({ display: "flex" })}>
          <TextInput ref={ref} id="email" name="email" placeholder="Enter your email" />
          <Button loading={loading} type="submit" ml="xs">
            Subscribe
          </Button>
        </Box>
      </form>
      {subscribeSuccessful && (
        <>
          <Box />
          <Box>
            <Text align="right">Successfully subscribed!</Text>
          </Box>
        </>
      )}
      {error && (
        <>
          <Box />
          <Box>
            <Text color="red" align="right">
              Error adding email, please try again.
            </Text>
          </Box>
        </>
      )}
    </SimpleGrid>
  );
};

export default Newsletter;
