import Head from "next/head";
import { useRouter } from "next/router";

import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    [theme.fn.largerThan("sm")]: {
      padding: "0 2rem",
    },
    [theme.fn.smallerThan("sm")]: {
      padding: "0 1rem",
    },
    maxWidth: "52rem",
    margin: "auto",
  },
}));
export default function Layout({ children, meta: pageMeta }) {
  const { classes, cx } = useStyles();
  const router = useRouter();

  const hideNavBar = router.pathname === "/submit";

  return (
    <>
      <Navbar hideNavBar={hideNavBar} />
      <div className={classes.container}>
        <main id="skip">{children}</main>
      </div>
      <Box py="md" />
      <Footer />
    </>
  );
}
