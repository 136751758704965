import * as React from "react";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    style={{
      enableBackground: "new 0 0 1000 1000",
    }}
    xmlSpace="preserve"
    width="64"
    height="64"
    {...props}
  >
    <style>{".st0{fill:#31A9B8}"}</style>
    <g id="Guides">
      <path
        id="XMLID_2569_"
        className="st0"
        d="M449.33 354.55c9.25-20.53 19.98-37.97 31.43-52.79 4.17-5.4 3.49-13.02-1.56-17.61-30.1-27.4-60.75-38.99-78.23-43.71a12.83 12.83 0 0 0-14.48 6c-9.02 15.69-22.51 45.56-24.42 86.22a12.994 12.994 0 0 0 11.35 13.55c18.57 2.38 38.5 7.13 59.56 15.1 6.39 2.43 13.54-.53 16.35-6.76z"
      />
      <path
        id="XMLID_2566_"
        className="st0"
        d="M361.39 433c-7.98-21.07-12.76-41-15.15-59.58-.87-6.76-6.74-11.66-13.55-11.34-40.67 1.91-70.54 15.39-86.24 24.42a12.836 12.836 0 0 0-6 14.48c4.72 17.48 16.3 48.14 43.71 78.25 4.59 5.04 12.21 5.73 17.6 1.56 14.83-11.45 32.31-22.17 52.85-31.42 6.24-2.82 9.2-9.98 6.78-16.37z"
      />
      <path
        id="XMLID_2563_"
        className="st0"
        d="M519.22 301.77c11.45 14.83 22.17 32.31 31.42 52.85 2.81 6.23 9.97 9.2 16.36 6.78 21.07-7.98 41-12.76 59.59-15.15 6.76-.87 11.66-6.74 11.34-13.55-1.91-40.67-15.39-70.55-24.42-86.25a12.836 12.836 0 0 0-14.48-6c-17.48 4.71-48.14 16.3-78.25 43.72a12.988 12.988 0 0 0-1.56 17.6z"
      />
      <path
        id="XMLID_2560_"
        className="st0"
        d="M637.06 554.32c-1 .42-2.05.81-3.07 1.22 10.8 25.32 16.95 49.13 19.77 71.05.87 6.76 6.74 11.66 13.55 11.34 40.67-1.9 70.54-15.38 86.24-24.41 5.06-2.91 7.52-8.85 6-14.48-4.72-17.48-16.3-48.13-43.71-78.24a13.017 13.017 0 0 0-17.63-1.55c-16.79 12.98-36.94 25.04-61.15 35.07z"
      />
      <path
        id="XMLID_2557_"
        className="st0"
        d="M479.22 715.84c5.04-4.59 5.73-12.21 1.56-17.6-13.51-17.49-25.99-38.67-36.26-64.22-1.01.43-2.03.89-3.03 1.31-24.21 10.03-46.99 15.75-68.04 18.44-6.77.87-11.69 6.74-11.37 13.56 1.91 40.66 15.39 70.53 24.42 86.23 2.91 5.06 8.85 7.52 14.48 6 17.47-4.72 48.13-16.31 78.24-43.72z"
      />
      <path
        id="XMLID_2554_"
        className="st0"
        d="M474.1 373.98c14.42.19 37.37.19 51.79.01 6.42-.08 10.71-6.72 8.08-12.58-8.02-17.84-17.14-33.16-26.85-46.27-3.52-4.76-10.74-4.76-14.26 0-9.71 13.11-18.83 28.42-26.85 46.26-2.61 5.85 1.68 12.49 8.09 12.58z"
      />
      <path
        id="XMLID_2551_"
        className="st0"
        d="M392.58 429.2c10.33-10.07 26.56-26.29 36.63-36.62 4.48-4.6 2.82-12.33-3.18-14.61-18.29-6.95-35.57-11.32-51.7-13.73-5.86-.87-10.96 4.23-10.08 10.08 2.41 16.13 6.78 33.41 13.73 51.69 2.27 6.01 10 7.67 14.6 3.19z"
      />
      <path
        id="XMLID_2548_"
        className="st0"
        d="M373.98 525.9c.19-14.42.19-37.37.01-51.79-.08-6.42-6.72-10.71-12.58-8.08-17.84 8.02-33.16 17.14-46.27 26.85-4.76 3.52-4.76 10.74 0 14.26 13.11 9.71 28.42 18.83 46.26 26.85 5.85 2.62 12.49-1.67 12.58-8.09z"
      />
      <path
        id="XMLID_2545_"
        className="st0"
        d="M429.2 607.43c-10.07-10.33-26.29-26.56-36.62-36.63-4.6-4.48-12.33-2.82-14.61 3.18-6.95 18.29-11.32 35.57-13.73 51.7-.87 5.86 4.23 10.96 10.08 10.08 16.13-2.41 33.41-6.78 51.69-13.73 6.01-2.28 7.67-10 3.19-14.6z"
      />
      <path
        id="XMLID_2542_"
        className="st0"
        d="M525.9 626.03c-14.42-.19-37.37-.19-51.79-.01-6.42.08-10.71 6.72-8.08 12.58 8.02 17.84 17.14 33.16 26.85 46.27 3.52 4.76 10.74 4.76 14.26 0 9.71-13.11 18.83-28.42 26.85-46.26 2.62-5.86-1.67-12.5-8.09-12.58z"
      />
      <path
        id="XMLID_2539_"
        className="st0"
        d="M607.43 570.8c-10.33 10.07-26.56 26.29-36.63 36.62-4.48 4.6-2.82 12.33 3.18 14.61 18.28 6.95 35.57 11.32 51.7 13.73 5.86.87 10.96-4.23 10.08-10.08-2.41-16.13-6.78-33.41-13.73-51.69-2.28-6-10.01-7.67-14.6-3.19z"
      />
      <path
        id="XMLID_2536_"
        className="st0"
        d="M626.03 474.11c-.19 14.42-.19 37.37-.01 51.79.08 6.42 6.72 10.71 12.58 8.08 17.84-8.02 33.16-17.14 46.27-26.85 4.76-3.52 4.76-10.74 0-14.26-13.11-9.71-28.42-18.83-46.26-26.85-5.86-2.62-12.5 1.67-12.58 8.09z"
      />
      <path
        id="XMLID_2533_"
        className="st0"
        d="M570.8 392.58c10.07 10.33 26.29 26.56 36.62 36.63 4.6 4.48 12.33 2.82 14.61-3.18 6.95-18.29 11.32-35.57 13.73-51.7.87-5.86-4.23-10.96-10.08-10.08-16.13 2.41-33.41 6.78-51.69 13.73-6.01 2.27-7.67 10-3.19 14.6z"
      />
      <path
        id="XMLID_2530_"
        className="st0"
        d="M550.66 645.43c-9.18 20.37-19.81 37.72-31.16 52.46a13.003 13.003 0 0 0 1.59 17.61c30 27.17 60.52 38.98 77.9 43.87a12.84 12.84 0 0 0 14.61-5.97c9.02-15.73 22.42-45.54 24.33-86.07.32-6.81-4.58-12.68-11.35-13.55-18.58-2.39-38.5-7.15-59.56-15.12-6.4-2.42-13.55.54-16.36 6.77z"
      />
      <path
        id="XMLID_2525_"
        className="st0"
        d="M246.45 613.51c15.69 9.02 45.56 22.51 86.23 24.41 6.81.32 12.68-4.58 13.55-11.35 2.38-18.57 7.13-38.5 15.1-59.55 2.42-6.39-.55-13.54-6.77-16.35-20.52-9.25-37.97-19.98-52.78-31.43-5.4-4.17-13.01-3.49-17.61 1.56-27.41 30.1-38.99 60.75-43.71 78.23-1.53 5.63.92 11.57 5.99 14.48z"
      />
      <path
        id="XMLID_2522_"
        className="st0"
        d="M753.55 386.49c-15.69-9.02-45.56-22.51-86.22-24.41a12.994 12.994 0 0 0-13.55 11.35c-2.38 18.57-7.13 38.5-15.1 59.55-2.42 6.39.55 13.54 6.77 16.35 20.52 9.25 37.97 19.98 52.78 31.43 5.4 4.17 13.02 3.49 17.61-1.56 27.4-30.1 38.99-60.74 43.71-78.23 1.52-5.63-.93-11.57-6-14.48z"
      />
    </g>
  </svg>
);

export default Logo;
