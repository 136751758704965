import dayjs from "dayjs";

import { createStyles, Anchor, Group, ActionIcon, Text, Avatar, Box, Divider } from "@mantine/core";
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from "@tabler/icons";
const day = dayjs().format("YYYY");
import Logo from "./Logo";
import Newsletter from "./Newsletter";
import Link from "next/link";

const useStyles = createStyles((theme) => ({
  footer: {
    paddingBottom: 24,
    maxWidth: "52rem",
    margin: "auto",
    // borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]}`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]}`,
    borderColor: theme.colors.blue[1],
    padding: `${theme.spacing.md}px ${theme.spacing.md}px`,

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.sm,
    },
  },
}));

const links = [
  { label: "Blog", link: "/blog" },
  { label: "Contact", link: "/contact" },
];
export default function Footer() {
  const { classes } = useStyles();
  const items = links.map((link) => (
    <Anchor color="dimmed" key={link.label} href={link.link} sx={{ lineHeight: 1 }} onClick={(event) => event.preventDefault()} size="sm">
      {link.label}
    </Anchor>
  ));

  return (
    <Box sx={(theme) => ({ backgroundColor: theme.colors.blue[2] })}>
      <div className={classes.footer}>
        <Newsletter />

        <div className={classes.inner}>
          {/* <Group>
          <Logo />
          <Text weight={"bold"}>ReactNativeJobs.com</Text>
        </Group> */}

          {/* <Group className={classes.links}>{items}</Group> */}
          <Link passHref href={"https://youtube.com/jimck"}>
            <Group component="a" target="_blank" position={"center"} my="lg">
              <Text size={"sm"} sx={(theme) => ({ color: theme.colors.blue[11] })}>
                Built by
              </Text>
              <Avatar radius={100} src={"/images/jimmycook.png"} alt="picture of jimmy cook" />
              <Text size="md" weight={"500"} sx={(theme) => ({ color: theme.colors.blue[11] })}>
                Jimmy Cook
              </Text>
            </Group>
          </Link>

          <Group spacing="xs" position="right" noWrap>
            <Link href="https://twitter.com/wjimmycook" passHref>
              <ActionIcon component="a" size="lg" target="_blank" variant="default" radius="xl">
                <IconBrandTwitter size={18} stroke={1.5} />
              </ActionIcon>
            </Link>
            <Link href={"https://youtube.com/jimck"} passHref>
              <ActionIcon component="a" target={"_blank"} size="lg" variant="default" radius="xl">
                <IconBrandYoutube size={18} stroke={1.5} />
              </ActionIcon>
            </Link>
          </Group>
        </div>

        <Text sx={(theme) => ({ color: theme.colors.blue[11] })} size="sm" align="center">
          &copy; {day} ReactNativeJobs.com. All rights reserved.
        </Text>
      </div>
    </Box>
  );
}
